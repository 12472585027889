import "../Home.css"

export default function HomeBanner() {
  return (
      <section className="container-home">
          <h1>NayaSport</h1>
      </section>
  )
}

