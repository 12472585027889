import React from 'react'
import './PrivacyPolicies.css'

function Text() {
    return (
        <div className='privacy-policies'>
            <h2>Política de Privacidad</h2>

            Esta Política de Privacidad describe cómo nuestra empresa recopila, utiliza y protege la
            información personal que recopilamos de los clientes en nuestra tienda virtual de ropa deportiva.
            Valoramos y respetamos la privacidad de nuestros clientes y nos comprometemos a proteger sus
            datos personales de acuerdo con las leyes de privacidad aplicables. Al utilizar nuestros servicios y
            proporcionarnos su información personal, usted acepta los términos descritos en esta política.
            <br />
            <span className='text-strong'>Recopilación de Información Personal</span>
            <br />
            Recopilamos la información personal que usted nos proporciona voluntariamente a través de nuestros formularios
            de contacto o al realizar una solicitud de cotización de uniformes personalizados. Esta información puede incluir, entre otros datos:
            <br />
            Nombre y apellidos
            Dirección de correo electrónico
            Número de teléfono
            Información de entrega y facturación
            Uso de la Información Personal
            <br />
            <span className='text-strong'>Utilizamos la información personal recopilada con los siguientes fines:</span>

            <ul>
                <li>Procesar y enviar cotizaciones de uniformes personalizados.</li>
                <li>Comunicarnos con usted para responder a sus consultas y proporcionarle información relevante.</li>
                <li>Mejorar nuestros productos y servicios.</li>
                <li>Enviar comunicaciones promocionales o informativas, previa autorización explícita.</li>
                <li>Divulgación de la Información Personal</li>
            </ul>

            No compartiremos, venderemos ni alquilaremos su información personal a terceros sin su consentimiento, 
            excepto cuando sea necesario para cumplir con una solicitud específica de entrega y envío de los 
            uniformes personalizados, o si estamos obligados por ley.
            <br />
            <span className='text-strong'>Seguridad de la Información Personal</span>
            <br />
            Implementamos medidas de seguridad adecuadas para proteger su información personal y 
            garantizar su confidencialidad. Esto incluye el uso de tecnologías y prácticas de seguridad adecuadas
            para prevenir el acceso no autorizado, la divulgación, el uso indebido o la alteración de su información 
            personal.
            <br />
            <span className='text-strong'>Sus Derechos</span>
            <br />
            Usted tiene ciertos derechos sobre su información personal y puede solicitar acceso, corrección, 
            actualización o eliminación de sus datos personales en cualquier momento. Si desea ejercer alguno de 
            estos derechos o tiene alguna pregunta sobre cómo se maneja su información, puede ponerse en contacto 
            con nosotros utilizando la información de contacto que se proporciona al final de esta política.
            <br />
            <span className='text-strong'>Cambios en la Política de Privacidad</span>
            <br />
            Nos reservamos el derecho de actualizar y modificar esta Política de Privacidad en cualquier momento. Cualquier cambio será publicado en nuestro sitio web y entrará en vigor a partir de la fecha de publicación. Le recomendamos que revise periódicamente esta política para estar informado sobre cómo protegemos y utilizamos su información personal.
            <br />
            <span className='text-strong'>Contacto</span>
            <br />
            Si tiene alguna pregunta, inquietud o solicitud relacionada con esta Política de Privacidad, 
            no dude en ponerse en contacto con nosotros a través de los siguientes datos de contacto:
            <br /><br />
            <span className='text-strong'>
                Naya Sport<br />
                3174703402<br />
                naya.sports@gmail.com<br />
                Fecha de entrada en vigor: 2023<br />
            </span>
        </div>
    )
}

export default Text