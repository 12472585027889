import React from 'react'
import Text from '../components/PrivacyPolicies/Text'

function PrivacyPolicies() {
  return (
    <div>
        <Text />
    </div>
  )
}

export default PrivacyPolicies